/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'yin-yang': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.167 4.5a1.167 1.167 0 11-2.334 0 1.167 1.167 0 012.334 0"/><path pid="1" d="M8 0a8 8 0 100 16A8 8 0 008 0M1 8a7 7 0 017-7 3.5 3.5 0 110 7 3.5 3.5 0 100 7 7 7 0 01-7-7m7 4.667a1.167 1.167 0 110-2.334 1.167 1.167 0 010 2.334"/>',
    },
});
